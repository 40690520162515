<template>
  <b-nav-item-dropdown
    :lazy="true"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    left
    @show="buscarDados"
  >
    <template #button-content>
      <feather-icon
        :badge="downloads.filter(where => (where.statusBaixa == 'concluído' || where.statusBaixa == 'concluído com falhas') && where.statusDownload == 'nao baixado').length"
        badge-classes="bg-success"
        class="text-body"
        icon="DownloadCloudIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex px-1 py-1">
        <h4 class="notification-title mb-0 mr-auto">
          Downloads
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ quantidadeConcluidos }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="downloads.length > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-row
        v-for="download in downloads"
        :key="download.id"
        class="ml-50 w-100"
      >
        <b-media class="mr-1">
          <template #aside>
            <b-avatar
              size="32"
              :variant="download.type"
            >
              <feather-icon :icon="download.icon" />
              <span
                v-if="download.status == 4"
                style="position: absolute; left: 20px; top: -2px"
                class="text-warning"
              >
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </span>
            </b-avatar>
          </template>
          <b-row>
            <b-col cols="10">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ download.descricao }}
                </span>
              </p>
              <small class="notification-text">{{ download.subtitulo }}</small>
            </b-col>
            <b-col
              v-if="(download.status == 3 || download.status == 4) && download.nomeArquivo"
              cols="2"
            >
              <!-- download -->
              <feather-icon
                class="text-body"
                icon="DownloadIcon"
                size="21"
                @click="downloadUrl(download)"
              />
            </b-col>
          </b-row>
        </b-media>
      </b-row>
    </vue-perfect-scrollbar>
    <div
      v-else
      class="my-1 text-secondary"
      style="text-align: center;"
    >
      Não existem downloads
    </div>
  </b-nav-item-dropdown>
</template>

<script>
/* eslint-disable no-param-reassign */
import { ref, computed } from '@vue/composition-api'
import {
  BNavItemDropdown, BBadge, BMedia, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import api from './services/api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const downloads = ref([])

    const preparaVisualizacao = arquivos => {
      const itens = arquivos
      itens.forEach(element => {
        switch (element.statusBaixa) {
          case 'em execução':
            element.type = 'light-warning'
            element.icon = 'ClockIcon'
            break
          case 'erro':
            element.type = 'light-danger'
            element.icon = 'XIcon'
            break
          case 'concluído':
            element.type = 'light-success'
            element.icon = 'DownloadCloudIcon'
            break
          case 'concluído com falhas':
            element.type = 'light-success'
            element.icon = 'DownloadCloudIcon'
            break
          default:
            break
        }
      })
      downloads.value = itens
      return itens
    }

    const buscarDados = async () => {
      const result = await api.get()
      preparaVisualizacao(result.data)
    }
    buscarDados()

    EventBus.$on('atualizaBaixa', () => buscarDados())

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const downloadUrl = download => {
      download.statusDownload = 'baixado'
      api.updateStatus(download.id)
      window.open(download.urlDownload)
    }

    const quantidadeConcluidos = computed(() => `${downloads.value.filter(where => where.status === 3 || where.status === 4).length} ${downloads.value.filter(where => where.status === 3).length === 1 ? 'concluído' : 'concluídos'}`)

    return {
      quantidadeConcluidos,
      downloads,
      buscarDados,
      perfectScrollbarSettings,
      downloadUrl,
    }
  },
}
</script>

<style>

</style>
