import axios from '@axios'

const PREFIX = 'baixa-lotes'

export default {

  get() {
    return axios.get(`${PREFIX}`)
  },
  updateStatus(id) {
    return axios.put(`${PREFIX}/${id}/update-status`)
  },
}
